<template>
	<div class="LubokShopView view" v-if="viewIsReady">
		<LuMobileHeader
			class="app__mobileNav"
			v-if="$root.isSmallDevice"
		></LuMobileHeader>

		<div class="grid grid--pageTemplateLinear hSpace hSpace--page vSpace vSpace--page maxWidth maxWidth--page">
			<!-- header: title + filter text -->
			<div>
				<LuPageHeader
					:title="'Shop'"
					:subTitle="filterPost ? filterPost.title.rendered : null"
					:text="filterPost ? $root.getTranslatedAcfValue(filterPost.acf, 'text') : null"
				></LuPageHeader>
			</div>
			<!-- content: products -->
			<div>
				<div class="vSpace vSpace--shopProductsTop border border--top border--strong">
					<LuProductTeasers
						:products="posts"
					></LuProductTeasers>

					<!--
					<MhGrid name="productsTeaser">
						<LubokProductTeaser v-for="post in posts" :key="post.id" :post="post"
						></LubokProductTeaser>
					</MhGrid>
					 -->
				</div>
			</div>
			<!-- footer: pagination -->
			<div>
				<MhPagination
					class="mhPagination--shop vSpace vSpace--pagination"
					@gotoPage="addPagedQuery"
					:currentPage="currentPage"
					:paginationLinks="paginationLinks">
				</MhPagination>
			</div>
		</div>

		<!--
		<pre data-name="posts">{{posts}}</pre>
		<pre data-name="totalPosts">{{totalPosts}}</pre>
		<pre data-name="totalPages">{{totalPages}}</pre>
		<pre data-name="currentPage">{{currentPage}}</pre>
		-->
	</div>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'
	import restHandler from '@/mixins/restHandler/v1/restHandler.mixin.js'
	import ViewMixin from './View.mixin.js'
	import MhSmartHeader from '@/components/MhSmartHeader/MhSmartHeader.vue'
	import MhDevGrid from '@/components/MhDevGrid/MhDevGrid.vue'
	import MhPagination from '@/components/MhPagination/MhPagination.vue'
	import LuPageHeader from '@/components/LuPageHeader.vue'
	import LuProductTeasers from '@/components/LuProductTeasers.vue'
	import LuMobileHeader from '@/components/LuMobileHeader.vue'

	export default {
		name: 'LuShopView',
		components: {
			MhSmartHeader,
			MhDevGrid,
			MhPagination,
			LuPageHeader,
			LuProductTeasers,
			LuMobileHeader,
		},
		mixins: [
			restHandler,
			ViewMixin,
		],
		props: {},
		data() {
			return {
				paginationLinks : null,
				currentPage     : null,
				posts           : [],
			}
		},
		watch: {
			'$route.query.paged': {
				handler: function( to, from ) {
					const paged = to ? to : 1

					this.doFetch( paged )
				},
				deep: true,
			},
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			filterPost() {
				//const filterSlug = this.$route.params.slug
				const filterSlug = this._.get( this.$route, 'query.filter' )
				const productFilters = this.app.productFilters
				const filterPost = this._.find( productFilters, { slug : filterSlug } )

				if( filterPost ){
					return filterPost
				}else{
					return null
				}
			},
		},
		methods: {
			addPagedQuery( paged ){
				const routeName = this.$route.name
				const filterSlug = this._.get( this.$route, 'query.filter' )
				let query = {}

				query.paged = paged
				if( filterSlug ) query.filter = filterSlug

				//this.$router.push({ name: this.$route.name, query: { paged : paged } })
				this.$router.push({ name: this.$route.name, query: query })
			},
			doFetch( paged ) {
				let params = {
					//perPage : 30,
					perPage : 30,
					paged   : paged,
				}

				const filterSlug = this._.get( this.$route, 'query.filter' )
				//console.log('filterSlug:', filterSlug)

				// wenn ein filter gesetzt wird dieser dem request hinzugefügt
				//if( this.$route.name == 'FilteredShopView' ){
				if( filterSlug ){
					params.filterSlug = filterSlug
				}

				// send loading to MhView to show the spinner
				EventBus.$emit('stateChanged', 'loading')

				// fetch products
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/lubok__products',
					params : params,
					callback : (response) => {
						this.currentPage     = response.data.info.currentPage
						this.paginationLinks = response.data.info.paginationLinks
						this.posts           = response.data.result

						this.viewIsReady = true // this comes from View.mixin.js

						EventBus.$emit('ViewEvent', {
							fromComponent : this.$options.name,
							eventName     : 'ready',
						})

						// send loaded to MhView to hide the spinner again
						EventBus.$emit('stateChanged', 'loaded')
					},
				})
			},
		},
		created() {},
		mounted() {
			// if ?paged= use this otherwise 1
			const paged = this.$route.query.paged ? this.$route.query.paged : 1

			this.doFetch( paged )

			this.title = this.$t('nav.shop')
		},
	}
</script>

<style lang="less">
	.LubokShopView {
		//color: white;
	}
</style>
